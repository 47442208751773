import * as React from 'react';
import { Seo } from '../components/seo';
import { Layout } from '../layout';
import { BreadCrumb } from '../section/breadcrumb';

import LOGO_ORDERED_FURNITURE from '../assets/svg/logo-ordered-furniture.svg';
import ORDERED_FURNITURE from '../assets/img/ordered-furniture.jpeg';
import ORDERED_FURNITURE_IMG from '../assets/img/bg-ordered-furniture.jpeg';

const OrderedFurniturePage = () => {
  return (
    <Layout>
      <Seo
        title="別注家具"
        description="一点一点、空間に合わせてオーダーメイドで作る家具。FINEでは自社工場での一貫生産と熟練した職人の技術、機械との連携によりミリ単位から家具製作が可能です。店舗ごとの業種やコンセプトに馴染み、その魅力を一層高めるような家具づくりを追求しております。造形的な美しさはもちろん、実務における機能性も両立させ、商空間をしなやかに支えます。"
      />
      {/* <!-- ヘッダータイトル --> */}
      <section className="mb-6">
        <div
          style={{ backgroundImage: `url(${ORDERED_FURNITURE_IMG})` }}
          className="relative flex items-center justify-center 2xl:-mt-2 w-screen h-40vh bg-cover bg-center overflow-hidden md:h-60vh"
        >
          <h1 className="flex flex-col items-center justify-center tracking-widest">
            <span className="text-white text-2xl md:text-4xl">
              ORDERED FURNITURE
            </span>
            <span className="mt-2 text-white text-xs md:text-base">
              別注家具
            </span>
          </h1>
          <div>
            <svg
              className="wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current"
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      {/* <!-- 現在ページ --> */}
      <BreadCrumb title="別注家具" />
      {/* <!-- ご挨拶 --> */}
      <section className="flex flex-wrap mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-5xl">
        <div className="flex flex-col items-center justify-center mb-12 w-full lg:mb-20 lg:pr-6 lg:w-1/2">
          <div className="mb-3 md:mb-6">
            <img className="w-16 md:w-24" src={LOGO_ORDERED_FURNITURE} alt="" />
          </div>
          <div className="mb-8 w-full md:mb-12">
            <h2 className="text-primary-blue md:text-2xl md:leading-loose">
              精度を高め、
              <br />
              確かな製品を生み出す。
            </h2>
          </div>
          <div>
            <p className="text-xs tracking-widest leading-5 md:text-sm md:leading-7">
              一点一点、空間に合わせてオーダーメイドで作る家具。FINEでは自社工場での一貫生産と熟練した職人の技術、機械との連携によりミリ単位から家具製作が可能です。店舗ごとの業種やコンセプトに馴染み、その魅力を一層高めるような家具づくりを追求しております。造形的な美しさはもちろん、実務における機能性も両立させ、商空間をしなやかに支えます。
            </p>
          </div>
        </div>
        <div className="relative flex items-start justify-center w-full lg:pl-6 lg:w-1/2">
          <div className="mx-auto w-3/4 md:w-2/3 lg:w-full">
            <img
              className="w-full object-cover"
              src={ORDERED_FURNITURE}
              alt=""
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OrderedFurniturePage;
